<template>
  <div>
    <div class="row align-items-stretch" id="printMe">
      <div class="col-4">
        <div class="row justify-content-between">
          <div class="col p-1">Client</div>
          <div class="col p-1 fw-bold">
            <span
              v-if="sale.customer"
              :class="
                sale.customer.fullName.includes('Bloqué') ? 'text-danger' : ''
              "
            >
              {{ sale.customer.fullName }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nº de Téléphone</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer">
              {{ sale.customer.phone }}
            </span>
          </div>
        </div>

        <div class="row justify-content-between">
          <div class="col p-1">Nom Commercial (Société)</div>
          <div class="col p-1 fw-bold">
            <span v-if="sale.customer">
              {{ sale.customer.society }}
            </span>
          </div>
        </div>
      </div>
      <!-- ***** -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Quantité totale</div>
          <div v-if="sale.totalQuantity" class="col p-1 fw-bold">
            {{ sale.totalQuantity }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TTC</div>
          <div v-if="sale.totalPriceTTC" class="col p-1 fw-bold">
            {{ sale.totalPriceTTC | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total HT</div>
          <div v-if="sale.totalPriceHT" class="col p-1 fw-bold">
            {{ sale.totalPriceHT | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Total TVA</div>
          <div v-if="sale.totalPriceTVA" class="col p-1 fw-bold">
            {{ sale.totalPriceTVA | toFixedWithSpace }}
          </div>
        </div>
      </div>
      <!-- paiments -->
      <div class="col-4 justify-content-between">
        <div class="row">
          <div class="col p-1">Total Payé :</div>
          <div v-if="sale.amountPaid" class="col p-1 fw-bold">
            {{ sale.amountPaid | toFixedWithSpace }}
          </div>
          <div v-else class="col p-1 fw-bold text-danger">----------</div>
        </div>

        <div class="row">
          <div class="col p-1">Montant Impayé :</div>
          <div v-if="sale.amountUnpaid" class="col p-1 fw-bold">
            {{ sale.amountUnpaid | toFixedWithSpace }}
          </div>
        </div>

        <div class="row">
          <div class="col p-1">Échéance :</div>
          <div class="col p-1 fw-bold">
            {{ sale.deadline }}
          </div>
        </div>
      </div>
    </div>

    <br />
    <div class="row">
      <div class="col-2">Remarque :</div>
      <div class="col fw-bold">
        {{ sale.remark }}
      </div>
    </div>
    <br />

    <br />
    <div class="row justify-content-start align-items-end">
      <div class="col-auto">
        <label for="reference">Reference Facture</label>
        <input type="text" v-model="invoice.reference" class="form-control" />
      </div>
      <div class="col-auto">
        <label for=""> </label>
        <button class="btn btn-success" @click="createInvoice(invoice)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      invoice: {},
    };
  },
  computed: {
    ...mapGetters("sale", {
      sale: "getSale",
    }),
  },
  methods: {
    async createInvoice(invoice) {
      await (invoice.sale_reference = this.sale.reference);
      await this.$store.dispatch("invoice/store", invoice);
    },
  },

  mounted() {
    this.$store.dispatch("sale/show", this.$route.params.reference);
  },
};
</script>
